<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Company options</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Options for this company.</span>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md">
          <h5 class="overflow-ellipsis">Leave company <span class="label label-sm label-danger label-inline">Caution</span></h5>
          <span>
            This option will permanently unlink you from this company. In order to regain acces to this company you  must acquire an invite to join this company from one of the entitled parties.
          </span>
        </div>

        <div class="col-auto mt-3 my-md-auto text-right">
          <button
            type="button"
            class="btn btn-danger"
            @click.prevent="leaveCompany"
          >
            Leave company
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import companyLib, { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';
import User from '@/libs/classes/user';

export default {
  props: {
    company: {
      type: Company,
      default: null,
    },
  },

  methods: {
    leaveCompany () {
      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: `Leave ${this.company.name}`,
        message: `Are you sure that you want to leave <strong>${this.company.name}</strong>?<br /><br />Please type the name of the company to confirm:`,
        verifyString: this.company.name,
        removeButtonText: 'Leave',
        async onSubmit () {
          await vm.$ws.patch(`${User.uri}/me`, {
            body: [{
              action: 'array_remove_by_key_value',
              field: 'company_permissions',
              key: 'company_guid',
              key_value: vm.company._meta.guid,
            }],
          });

          vm.$auth.checkAuthenticated(); // Check authenticated, so the resource details will be reloaded
          companyLib.setCompany(null);
          vm.$router.push('/dashboard');
        },
      });
    },
  },
};
</script>
